import type { GetServerSideProps } from "next"
import dynamic from "next/dynamic"
import axios from "axios"
import { APP_HOST } from "@lib/env"
import { ProductCard, SearchProduct } from "@backend/dto/Product"
import Layout from "@components/layout/default/Layout"
import { ctaLogAPI, CTA_LOG_MEMO } from "@backend/ctaLogAPI"
import FirstSection from "@components/feature/home/FirstSection"
import {
  BannersDTO,
  currentBannerState,
} from "@components/feature/home/FirstSection/Banner"
import { useSetRecoilState } from "recoil"
import { useEffect, useState } from "react"
import Banner from "@components/feature/home/Banner"
import styled from "styled-components"
import { Margin } from "@components/ui"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import useSWR from "swr"
import { getFetcher } from "@lib/frontend/swr"
import Link from "next/link"
import { useRouter } from "next/router"
// import Product from "@components/feature/search/Product/MainPagePC"

type InformationButton = {
  name: string
  color?: string
}

type ProductProps = {
  productUrl: string
  artist: string
  artistUrl: string
  name: string
  image: string
  price: number
  isDiscounted: boolean
  isLiked: boolean
  beforeDiscountedPrice: number | null
  informationButtons?: InformationButton[]
}

const Product = dynamic(
  () => import("@components/feature/search/Product/MainPagePC"),
  {
    ssr: false,
  },
)

const ProductMobile = dynamic(
  () => import("@components/feature/search/Product"),
  {
    ssr: false,
  },
)

type Props = {
  products: ProductCard[]
  banners: BannersDTO
}
const props = {
  productUrl: "https://www.pie3d.com/p/clct4eto60004l808cg4yx0e6",
  artist: "이노랑",
  artistUrl: "https://www.pie3d.com/u/clbu9alu0000gl108f0gmxmx3",
  name: "귀엽게 반짝이는 겨울의 크리스마스 오너먼트 모음 귀엽게 반짝",
  image:
    "https://i.pie3d.com/ywmzKSmlQQjqL2Jiq-K8ePX75kiyCNDCUFtXG73AksY/w:1000/aHR0cHM6Ly9kMjZtMTdrMzdwY3B5ZS5jbG91ZGZyb250Lm5ldC91c2Vycy9jbGJ1OWFsdTAwMDBnbDEwOGYwZ214bXgzL3Byb2R1Y3RzL3RodW1ibmFpbHMvMm5vcmFuZ19jYWtlX3RodW1ibmFpbF9jdXBvbi1taW4ucG5n",
  price: 180000,
  isDiscounted: true,
  isLiked: false,
  beforeDiscountedPrice: 229000,
  informationButtons: [{ name: "3D 프린트" }, { name: "리깅", color: "red" }],
}

const props2 = {
  productUrl: "https://www.pie3d.com/p/clct4eto60004l808cg4yx0e6",
  artist: "이노랑",
  artistUrl: "https://www.pie3d.com/u/clbu9alu0000gl108f0gmxmx3",
  name: "귀엽게 반짝이는 겨울",
  image:
    "https://i.pie3d.com/ywmzKSmlQQjqL2Jiq-K8ePX75kiyCNDCUFtXG73AksY/w:1000/aHR0cHM6Ly9kMjZtMTdrMzdwY3B5ZS5jbG91ZGZyb250Lm5ldC91c2Vycy9jbGJ1OWFsdTAwMDBnbDEwOGYwZ214bXgzL3Byb2R1Y3RzL3RodW1ibmFpbHMvMm5vcmFuZ19jYWtlX3RodW1ibmFpbF9jdXBvbi1taW4ucG5n",
  price: 199000,
  isDiscounted: false,
  isLiked: false,
  beforeDiscountedPrice: 11000,
  informationButtons: [{ name: "3D 프린트" }, { name: "리깅", color: "red" }],
}

const products_hardcoded = [props, props2, props, props2, props, props2]

const Style = {
  Wrapper: styled.div`
    font-family: "SUIT";
    width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
      width: 890px;
    }
    @media screen and (max-width: 890px) {
      width: 100%;
    }
  `,
  GroupTitleWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1200px;
    @media screen and (max-width: 1200px) {
      width: 792px;
    }
  `,
  GroupTitleText: styled.div`
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
  `,
  GroupViewAllButton: styled.button`
    width: 158px;
    height: 56px;
    border: 1px solid #000000;
    border-radius: 2px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  `,
  ProductWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 387px);
    grid-template-rows: repeat(auto-fill, auto);
    gap: 19px;
    row-gap: 28px;
    margin: 20px auto;
    width: 1200px;
    @media screen and (max-width: 1200px) {
      width: 792px;
      gap: 18px;
    }
  `,
  MobileGroupTitleWrapper: styled.div`
    font-family: "SUIT";
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: calc(100% - 60px);
  `,
  MobileGroupTitleText: styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  `,
  MobileGroupViewAllButton: styled.button`
    width: fit-content;
    height: 56px;
    border: none;
    border-radius: 2px;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-decoration: underline;
  `,
  MobileProductWrapper: styled.div`
    margin: 10px 30px;
    width: calc(100% - 30px);
  `,
  MoreAssetsText: styled.div`
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #2d2d2d;
  `,
  MoreAssetsButton: styled.button`
    width: 200px;
    height: 56px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    border: 1px solid #000000;
    border-radius: 2px;
    background-color: #ffffff;
  `,
}
const Content = ({
  title,
  products,
  isMobile = false,
}: {
  title: string
  products?: ProductProps[]
  isMobile?: boolean
}) => {
  const router = useRouter()
  return products ? (
    isMobile ? (
      <>
        <Style.MobileGroupTitleWrapper>
          <Style.MobileGroupTitleText>{title}</Style.MobileGroupTitleText>
          <Style.MobileGroupViewAllButton
            onClick={() => router.push("/p/search")}
          >
            View all
          </Style.MobileGroupViewAllButton>
        </Style.MobileGroupTitleWrapper>
        <Style.MobileProductWrapper>
          <Swiper
            spaceBetween={12}
            slidesPerView={1.1}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {products.map((product: any) => (
              <SwiperSlide key={product.productUrl}>
                <ProductMobile {...product} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Style.MobileProductWrapper>
      </>
    ) : (
      <>
        <Style.GroupTitleWrapper>
          <Style.GroupTitleText>{title}</Style.GroupTitleText>
          <Style.GroupViewAllButton onClick={() => router.push("/p/search")}>
            View all
          </Style.GroupViewAllButton>
        </Style.GroupTitleWrapper>
        <Style.ProductWrapper>
          {products.map((product: any) => (
            <Product key={product.productUrl} {...product} />
          ))}
        </Style.ProductWrapper>
      </>
    )
  ) : (
    <></>
  )
}

export default function Page({ products, banners }: Props) {
  const setCurrentBanner = useSetRecoilState(currentBannerState)
  useEffect(() => {
    setCurrentBanner(banners[0])
  }, [setCurrentBanner, banners])
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 890) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const { data: productsWithGroup } = useSWR<any>(
    "/api/pie/home-exhibition-products?take=3&page=1",
    getFetcher,
  )

  return (
    <Layout>
      <FirstSection banners={banners} />
      <Style.Wrapper>
        <Margin size={isMobile ? 50 : 100} direction={"column"} />
        <Banner
          title="쉽게 3D 에셋을 찾으세요"
          description="블렌더, 시포디, 스케치업, 마야 등\n다양한 크리에이터의 에셋을 쉽게 찾고, 내 프로젝트에 활용하세요"
          tags={["#스케치업", "#시포디", "#블렌더"]}
          backgroundColor={"#D7FA9E"}
          tagBackgroundColor={"#F3FFE7"}
          url={"/p/search"}
          bannerImgSrc={"/home/minibanner/1.png"}
        />
        {!isMobile && <Margin size={100} direction={"column"} />}
        <Content
          title={productsWithGroup?.[0]?.title}
          products={productsWithGroup?.[0]?.products}
          isMobile={isMobile}
        />
        <Margin size={isMobile ? 50 : 100} direction={"column"} />
        <Banner
          title="콘텐츠의 완성은 배경"
          description="내 프로젝트를 풍부하게 만들어 줄\n배경 에셋이 필요할 때"
          tags={["#스케치업", "#실내실외", "#웹툰배경", "#애니메이션"]}
          backgroundColor={"#9BC3FF"}
          tagBackgroundColor={"#E0F6FF"}
          url={"/p/search?categoryIdList=77264566-fa8f-4049-a95d-93389120473a"}
          bannerImgSrc={"/home/minibanner/2.png"}
        />
        <Margin size={100} direction={"column"} />
        <Content
          title={productsWithGroup?.[1]?.title}
          products={productsWithGroup?.[1]?.products}
          isMobile={isMobile}
        />
        <Margin size={isMobile ? 50 : 100} direction={"column"} />
        <Banner
          title="에셋, 이제 내가 원하는 대로"
          description="가성비 있는 패키지 구성으로 필요한 에셋만 콕콕!\n내가 원하는 대로 골라 담으세요"
          tags={["#패키지", "#가성비", "#다용도"]}
          backgroundColor={"#9ADCAB"}
          tagBackgroundColor={"#D9F1DF"}
          url={"/cart"}
          bannerImgSrc={"/home/minibanner/3.png"}
        />
        <Margin size={100} direction={"column"} />
        <Content
          title={productsWithGroup?.[2]?.title}
          products={productsWithGroup?.[2]?.products}
          isMobile={isMobile}
        />
        <Margin size={160} direction={"column"} />
        <Style.MoreAssetsText>
          더 많은 에셋을 구경해보세요.
        </Style.MoreAssetsText>
        <Margin size={24} direction={"column"} />
        <Style.MoreAssetsText>
          <Link href="/p/search" passHref>
            <a>
              <Style.MoreAssetsButton>DISCOVER</Style.MoreAssetsButton>
            </a>
          </Link>
        </Style.MoreAssetsText>
        <Margin size={210} direction={"column"} />
      </Style.Wrapper>
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps<Props> = async (
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  context,
) => {
  try {
    const jwt = context.req.cookies.jwt
    const [{ data: products }] = await Promise.all([
      axios.get<SearchProduct>(
        `${APP_HOST}/api/products/search?order_by=saledAt&take=12&page=1&original=true`,
      ),

      ctaLogAPI({ name: "/", memo: CTA_LOG_MEMO.PAGE_CONNECT }, jwt),
    ])
    return {
      props: {
        products,
        banners: [
          {
            src: "/home/mainbanner/mainbanner-05.jpg",
            bg: "#EEEAF6",
            order: 1,
          },
          {
            src: "/home/mainbanner/mainbanner-01.jpg",
            bg: "#E0F6FF",
            order: 2,
            url: "https://creator.pie3d.com",
          },
          {
            src: "/home/mainbanner/mainbanner-02.jpg",
            bg: "#D9F1DF",
            order: 3,
          },
          {
            src: "/home/mainbanner/mainbanner-04.jpg",
            bg: "#FFFAE5",
            order: 4,
          },
          {
            src: "/home/mainbanner/mainbanner-03.jpg",
            bg: "#FFECDF",
            order: 5,
            url: "/p/referral",
          },
        ],
      },
    }
  } catch (error) {
    return {
      props: {
        products: [],
        banners: [],
      },
    }
  }
}
