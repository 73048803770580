import dynamic from "next/dynamic"
import { useRecoilValue } from "recoil"
import styled from "styled-components"
import Banner, { BannersDTO, currentBannerState } from "./Banner"

const KeyMessage = dynamic(import("./KeyMessage"), { ssr: false })

type Props = {
  banners: BannersDTO
}

const Style = {
  Wrapper: styled.div`
    max-width: 1400px;
    margin: 0 auto;
    display: flex;

    @media screen and (max-width: 1400px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `,
  Line: styled.div`
    display: none;
    @media screen and (max-width: 1400px) {
      display: block;
      width: 100%;
      height: 0;
      border-bottom: 1px solid black;
    }
  `,
}

export default function FirstSection({ banners }: Props) {
  const currentBanner = useRecoilValue(currentBannerState)
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: currentBanner.bg,
        borderBottom: "1px solid black",
      }}
    >
      <Style.Wrapper>
        <KeyMessage />
        <Style.Line />
        <Banner banners={banners} />
      </Style.Wrapper>
    </div>
  )
}
