import { useInterval } from "@hooks/useInterval"
import { atom, useRecoilState } from "recoil"
import styled from "styled-components"

type BannerDTO = { src: string; bg: string; order: number; url?: string }

export type BannersDTO = BannerDTO[]

export const currentBannerState = atom<BannerDTO>({
  key: "currentBannerState",
  default: { src: "", bg: "inherit", order: 0 },
})

type Props = {
  banners: BannersDTO
}

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 556px;
    height: 556px;
    max-width: 556px;
    min-width: 556px;
    border-right: 1px solid black;
    border-left: 1px solid black;

    @media screen and (max-width: 556px) {
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      width: 100%;
      height: 100%;
      box-sizing: content-box;
      min-width: 200px;
    }
  `,
  BannerImg: styled.img`
    width: 556px;
    height: 556px;
    -webkit-user-drag: none;
    @media screen and (max-width: 556px) {
      width: 100%;
      height: 100%;
    }
  `,
  Left: styled.img`
    display: block;
    position: absolute;
    top: 50%;
    left: 17px;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-user-drag: none;
  `,
  Right: styled.img`
    display: block;
    position: absolute;
    top: 50%;
    right: 17px;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-user-drag: none;
  `,
}

export default function Banner({ banners }: Props) {
  const [currentBanner, setCurrentBanner] = useRecoilState(currentBannerState)

  const moveNextBanner = () => {
    setCurrentBanner((prevBanner) => {
      const targetBannerOrder = prevBanner.order + 1
      if (targetBannerOrder === banners.length + 1) {
        // 마지막에서 오른쪽 화살표 누름 -> 처음으로
        return banners[0]
      } else {
        return banners[targetBannerOrder - 1]
      }
    })
  }

  useInterval(moveNextBanner, 5000)

  return (
    <Style.Wrapper>
      <Style.BannerImg
        src={currentBanner.src}
        onClick={() => {
          if (currentBanner.url) window.location.href = currentBanner.url
        }}
        style={{
          cursor: currentBanner.url ? "pointer" : "default",
        }}
      />
      <Style.Left
        src="/home/arrow_left.svg"
        onClick={() => {
          setCurrentBanner((prevBanner) => {
            const targetBannerOrder = prevBanner.order - 1
            if (targetBannerOrder === 0) {
              // 첫번째에서 왼쪽 화살표 누름 -> 마지막으로
              return banners[banners.length - 1]
            } else {
              return banners[targetBannerOrder - 1]
            }
          })
        }}
      />
      <Style.Right src="/home/arrow_right.svg" onClick={moveNextBanner} />
    </Style.Wrapper>
  )
}
