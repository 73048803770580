import styled from "styled-components"

type Props = {
  title: string
  description: string
  tags: string[]
  url?: string
  backgroundColor?: string
  color?: string
  tagBackgroundColor?: string
  tagColor?: string
  bannerImgSrc?: string
}
type DivProps = {
  color?: string
  backgroundColor?: string
}
type BannerImgProps = {
  src?: string
}
const Style = {
  Wrapper: styled.div<DivProps>`
    font-family: "SUIT";
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1200px;
    height: 136px;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "white"};
    color: ${(props) => (props.color ? props.color : "black")};
    @media screen and (max-width: 1200px) {
      width: 890px;
    }
    @media screen and (max-width: 890px) {
      display: none;
    }
  `,
  TextWrapper: styled.div`
    margin-left: 238px;
    @media screen and (max-width: 1200px) {
      margin-left: 77px;
    }
  `,
  Title: styled.div`
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 16px;
  `,
  Description: styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  `,
  TagWrapper: styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 14px;
    bottom: 20px;
    right: 40px;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  `,
  Tag: styled.div<DivProps>`
    background-color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : "rgba(255, 255, 255, 0.8)"};
    color: ${(props) => (props.color ? props.color : "black")};
    height: 36px;
    border-radius: 18px;
    font-weight: 400;
    font-size: 15px;
    line-height: 36px;
    padding: 0 20px;
  `,
  BannerImg: styled.div<BannerImgProps>`
    width: 238px;
    height: 136px;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(${(props) => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  `,
}

const goto = (url?: string) => {
  if (url) window.location.href = url
}
export default function Banner(props: Props) {
  const { title, description, tags } = props
  return (
    <Style.Wrapper
      backgroundColor={props.backgroundColor}
      color={props.color}
      onClick={() => goto(props.url)}
      style={{ cursor: props.url ? "pointer" : "default" }}
    >
      {props.bannerImgSrc && <Style.BannerImg src={props.bannerImgSrc} />}
      <Style.TextWrapper>
        <Style.Title>{title}</Style.Title>
        {description.split("\\n").map((line) => (
          <Style.Description key={line}>{line}</Style.Description>
        ))}
      </Style.TextWrapper>
      <Style.TagWrapper>
        {tags.map((tag) => (
          <Style.Tag
            key={tag}
            backgroundColor={props.tagBackgroundColor}
            color={props.tagColor}
          >
            {tag}
          </Style.Tag>
        ))}
      </Style.TagWrapper>
    </Style.Wrapper>
  )
}
